import Image from 'next/image';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

interface Props {
  photoUrl: string;
}

export const MeetChefSection: React.FC<Props> = ({ photoUrl }) => {
  return (
    <section className="md:my-24 mx-auto flex flex-col md:flex-row w-full max-w-7xl items-center justify-evenly">
      <div className="max-w-md aspect-square w-full rounded-full px-6 relative overflow-hidden">
        <Image src={photoUrl} alt="Photo of Christer Rødseth" layout="fill" objectFit="cover" />
      </div>

      <div className="flex max-w-lg flex-col text-center px-4 md:text-left">
        <div className="text-sectionTitle font-extrabold">
          <FormattedMessage
            id={translations.pages.landing.meetChefSection.title}
            values={{
              br: <br />
            }}
          />
        </div>

        <div className="mt-14 font-extrabold">
          <FormattedMessage id={translations.pages.landing.meetChefSection.subtitle} />
        </div>

        <div className="text-gray">
          <FormattedMessage
            id={translations.pages.landing.meetChefSection.body}
            values={{
              br: <br />,
              //TODO investigate why this throws an error
              span: ((chunks) => <span className="text-red-700">{chunks}</span>) as unknown as React.ReactNode
            }}
          />
        </div>
      </div>
    </section>
  );
};
