import React from 'react';

import Image from 'next/image';

import dagbladet from './assets/dagbladet_wDice.png';
import chefHat from './assets/chefHat.png';
import dagbladet_rounded from './assets/dagbladet_rounded.png';

interface Props {
  videoUrl: string;
  title: string;
  subtitle: string;
}
export const HeroSection: React.FC<Props> = ({ videoUrl, title, subtitle }) => {
  return (
    <section className="relative mx-auto  aspect-video flex h-[80vh] min-h-[624px] md:mt-8 w-full flex-col items-center overflow:hidden  md:shadow-2xl md:max-w-7xl ">
      <video src={videoUrl} className="h-full object-cover w-full md:rounded-3xl" muted autoPlay loop playsInline />

      <div className="absolute inset-0 flex w-full flex-col items-center  px-5 justify-center  text-center z-30">
        <div className="w-40 absolute top-0 left-0 p-4 md:w-52 ">
          <Image src={chefHat} alt="chefHat" />
        </div>

        <div>
          <div className="max-w-xl text-4xl font-extrabold text-white sm:text-5xl md:text-heroTitle">{title}</div>

          <div className="max-w-3xl text-lg text-white md:text-headline-2">{subtitle}</div>
        </div>

        <div className="w-44 absolute bottom-6  hidden  md:flex ">
          <Image src={dagbladet_rounded} alt="Dagbladet rounded logo" />
        </div>

        <div className="w-44 absolute bottom-6 md:hidden">
          <Image src={dagbladet} alt="Dagbladet logo" />
        </div>
      </div>
    </section>
  );
};
