import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locales';
import { REGISTRATION_URL } from '@chew/common/config';

import { Button } from '@chew/common/components/Button';
import { BenefitItem } from '@chew/common/components/BenefitItem';

import { PricingPlanItem } from '@/components/PricingPlanItem';

interface Props {
  promo: string;
}

export const PricingSection: React.FC<Props> = ({ promo }) => {
  const intl = useIntl();

  return (
    <section className="my-12 flex w-full flex-col" id="pricing">
      <svg viewBox="0 0 1440 90" fill="none" xmlns="http://www.w3.org/2000/svg" className="-mb-1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0L60.0417 10C120.083 20 240.167 40 360.25 50C480.333 60 600.417 60 720.5 50C840.583 40 960.667 20 1080.75 25C1200.83 30 1320.92 57 1380.96 72L1441 87V89.3333L1380.96 88.3333C1320.92 88.3333 1200.83 88.3333 1080.75 88.3333C960.667 88.3333 840.583 88.3333 720.5 88.3333C600.417 88.3333 480.333 88.3333 360.25 88.3333C240.167 88.3333 120.083 88.3333 60.0417 88.3333H0V0Z"
          fill="#F7F5F1"
        />
      </svg>

      <div className="bg-pricing-section flex flex-col text-center pt-12 px-4 items-center">
        <div className="text-sectionTitle font-extrabold">
          <FormattedMessage id={translations.pages.landing.pricingSection.title} />
        </div>

        <div className="flex flex-col items-center mt-12 w-full md:flex-row md:gap-8 md:justify-center md:max-w-2xl">
          <PricingPlanItem
            title={intl.formatMessage({ id: translations.pages.landing.pricingSection.plans.yearly.title })}
            price={intl.formatMessage(
              { id: translations.pages.landing.pricingSection.plans.yearly.price.main },
              {
                span: (chunks: ReactNode) => <span className="text-6xl line-through">{`${chunks} `}</span>
              }
            )}
            subtext={intl.formatMessage({ id: translations.pages.landing.pricingSection.plans.yearly.price.subtext })}
            discount={20}
          />

          <div className="w-full max-w-[200px] h-[1px] bg-border my-9 md:h-36 md:w-[1px]"></div>

          <PricingPlanItem
            title={intl.formatMessage({ id: translations.pages.landing.pricingSection.plans.monthly.title })}
            price={intl.formatMessage(
              { id: translations.pages.landing.pricingSection.plans.monthly.price.main },
              {
                span: (chunks: ReactNode) => <span className="text-6xl">{`${chunks} `}</span>
              }
            )}
            subtext={intl.formatMessage({ id: translations.pages.landing.pricingSection.plans.monthly.price.subtext })}
          />
        </div>

        <div className="text-header text-orange-light mt-8 max-w-md">{promo}</div>

        <div className="flex flex-col gap-4 md:flex-row md:gap-0 mt-12 w-full md:justify-evenly items-center md:items-end max-w-2xl">
          <div className="flex flex-col gap-4">
            <div className="text-header text-left font-bold">
              <FormattedMessage id={translations.pages.landing.pricingSection.benefits.title} />
            </div>

            <BenefitItem
              text={intl.formatMessage({ id: translations.pages.landing.pricingSection.benefits.lessons })}
            />

            <BenefitItem
              text={intl.formatMessage({ id: translations.pages.landing.pricingSection.benefits.dashboard })}
            />
            <BenefitItem
              text={intl.formatMessage({ id: translations.pages.landing.pricingSection.benefits.quizzes })}
            />
          </div>
        </div>

        <Button
          onClick={() => window.open(REGISTRATION_URL, '_self')}
          appearance="gradient-orange"
          fat
          className="px-12 mt-10 md:mt-20 mb-8 text-2xl"
        >
          <FormattedMessage id={translations.inputs.buttons.tryFree} />
        </Button>

        <span className="text-sm mb-14 max-w-lg">
          <FormattedMessage
            id={translations.pages.landing.pricingSection.guarantee}
            // values={{
            //   //TODO investigate why this throws an errorr
            //   span: ((chunks) => <span className="font-extrabold">{chunks}</span>) as unknown as React.ReactNode
            // }}
          />
        </span>
      </div>
    </section>
  );
};
