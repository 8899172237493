import React from 'react';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { REGISTRATION_URL } from '@chew/common/config';

import { Button } from '@chew/common/components/Button';

import dots from '@/assets/dots.svg';

interface Props {
  videoUrl: string;
}

const topWave = (
  <svg viewBox="0 0 1440 54" fill="none" xmlns="http://www.w3.org/2000/svg" className="bg-how-it-works-background">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 41.0374L48.0333 38.3016C96.0667 35.5658 192.133 30.0941 288.2 35.5658C384.267 41.0374 480.333 57.4524 576.4 53.3487C672.467 49.2449 768.533 24.6225 864.6 19.1508C960.667 13.6791 1056.73 27.3583 1152.8 27.3583C1248.87 27.3583 1344.93 13.6791 1392.97 6.83957L1441 0H1392.97C1344.93 0 1248.87 0 1152.8 0C1056.73 0 960.667 0 864.6 0C768.533 0 672.467 0 576.4 0C480.333 0 384.267 0 288.2 0C192.133 0 96.0667 0 48.0333 0H0V41.0374Z"
      fill="white"
    />
  </svg>
);

const bottomWave = (
  <svg viewBox="0 0 1438 63" fill="none" xmlns="http://www.w3.org/2000/svg" className="bg-how-it-works-background">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-8.74228e-07 8.40001L59.9583 21C119.917 33.6 239.833 37.8 359.75 40.95C479.667 44.1 599.583 25.2 719.5 22.05C839.417 18.9 959.333 31.5 1079.25 25.2C1199.17 18.9 1319.08 25.2 1379.04 12.6L1439 0V63L1379.04 63C1319.08 63 1199.17 63 1079.25 63C959.333 63 839.417 63 719.5 63C599.583 63 479.667 63 359.75 63C239.833 63 119.917 63 59.9583 63L2.62268e-06 63L-8.74228e-07 8.40001Z"
      fill="white"
    />
  </svg>
);

const chefBowl = (
  <svg viewBox="0 0 77 68" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-20">
    <path
      d="M73.1068 29.1235C72.8505 28.9341 72.5023 28.8763 72.1471 29.0907C71.2874 29.6094 70.4257 30.1249 69.5636 30.6421C69.5661 30.5322 69.5443 30.4136 69.4903 30.2852C64.7894 19.0873 51.2037 13.7186 39.7487 16.3216C38.0053 16.7176 36.3064 17.2771 34.6728 17.9787C33.4157 16.2205 31.1846 15.1627 29.0887 16.1828C26.6928 17.3488 26.6685 20.0456 27.6082 22.2046C27.6114 22.21 27.6147 22.2154 27.6175 22.2225C21.6777 26.9878 17.5415 33.9268 16.8604 41.6741C16.3272 47.7348 17.9814 53.871 21.5128 58.8116C20.2839 59.5458 19.0567 60.2804 17.8309 61.0199C17.5036 61.2175 17.3839 61.5166 17.4038 61.8091C17.3007 62.0411 17.2907 62.318 17.4393 62.6032C18.5852 64.7959 21.9176 65.9417 24.2482 65.1577C25.2441 64.8233 26.1988 64.2194 27.1155 63.7176C30.8857 61.658 34.5825 59.4597 38.2777 57.2711C47.3145 51.9196 56.3 46.4805 65.2306 40.953C68.987 38.6279 75.9192 35.1363 73.7842 29.7075C73.6511 29.3705 73.3908 29.1865 73.1068 29.1235ZM30.0125 17.76C31.1643 17.3007 32.257 17.8961 32.9815 18.7693C31.6325 19.45 30.3359 20.2323 29.1043 21.1028C28.6335 19.898 28.6154 18.3175 30.0125 17.76ZM23.7814 28.6861C30.7537 19.6289 42.7646 15.1885 53.7702 18.7876C59.7521 20.7431 65.2504 24.8644 67.7334 30.7801C67.8026 30.9433 67.9009 31.0699 68.0181 31.1647C53.0874 40.1588 37.9332 48.7752 23.0037 57.7715C16.8809 49.0771 17.2706 37.1443 23.7814 28.6861ZM64.6084 39.1938C60.8661 41.5133 57.1084 43.8069 53.346 46.0931C45.0568 51.1282 36.7648 56.1782 28.3236 60.9566C27.0303 61.6886 25.7343 62.4281 24.4042 63.0916C24.2012 63.192 23.9981 63.297 23.7905 63.3872C23.9314 63.3271 23.7235 63.4364 23.5785 63.4653C22.173 63.7468 20.3603 63.3267 19.3858 62.2163C36.9056 51.6703 54.7555 41.6836 72.2773 31.1408C72.8029 34.8995 67.3521 37.493 64.6084 39.1938Z"
      fill="#FFC161"
    />
    <path
      d="M53.2113 15.0485C54.1596 14.8658 55.1636 14.7051 56.117 14.941C57.0703 15.1768 57.8836 15.7871 58.6373 16.3908C58.8379 16.5509 59.1062 16.389 59.0581 16.1369C58.8753 15.1887 58.7146 14.1847 58.9505 13.2313C59.1829 12.2917 59.7777 11.4892 60.3727 10.7452C60.4802 10.6759 60.4891 10.5329 60.4282 10.4247C60.4256 10.4204 60.423 10.4162 60.4204 10.4119C60.4172 10.4065 60.4139 10.4011 60.4113 10.3968C60.3429 10.2932 60.214 10.2348 60.1026 10.2976C59.167 10.4771 58.1798 10.6292 57.2412 10.3961C56.2879 10.1602 55.4746 9.54998 54.7209 8.94624C54.5203 8.78615 54.252 8.94804 54.3001 9.2001C54.4829 10.1484 54.6436 11.1523 54.4077 12.1057C54.1719 13.0591 53.5616 13.8724 52.9579 14.6261C52.7978 14.8266 52.9597 15.095 53.2117 15.0468L53.2113 15.0485ZM54.7051 12.8267C54.9848 12.1279 55.0679 11.3897 55.0164 10.6419C54.9952 10.3399 54.9566 10.0382 54.9092 9.73884C55.4111 10.1168 55.9408 10.4634 56.5222 10.6967C57.221 10.9764 57.9592 11.0594 58.7063 11.0069C59.0083 10.9857 59.3101 10.9471 59.6094 10.8997C59.2314 11.4016 58.8849 11.9313 58.6516 12.5127C58.3719 13.2115 58.2888 13.9497 58.3414 14.6968C58.3626 14.9988 58.4011 15.3006 58.4485 15.5999C57.9467 15.2219 57.417 14.8754 56.8355 14.6421C56.1368 14.3624 55.3986 14.2793 54.6514 14.3319C54.3494 14.3531 54.0477 14.3916 53.7483 14.439C54.1263 13.9372 54.4729 13.4075 54.7062 12.826L54.7051 12.8267Z"
      fill="#FFC161"
    />
    <path
      d="M50.6902 10.3464C51.2043 9.88829 51.2996 9.11198 50.9561 8.52572C50.6349 8.00798 50.0321 7.73924 49.4322 7.8538C48.8438 7.96576 48.371 8.46331 48.2841 9.05451C48.196 9.65818 48.4968 10.2731 49.0411 10.5566C49.5797 10.8378 50.2384 10.7478 50.6895 10.3453L50.6902 10.3464ZM48.5689 9.26922C48.5703 8.79841 48.8745 8.35866 49.3195 8.19848C49.8367 8.01231 50.4076 8.21836 50.6964 8.68243C50.9358 9.09138 50.9137 9.62007 50.6098 9.99231C50.3022 10.3682 49.7892 10.5152 49.3304 10.3543C48.8758 10.1952 48.5675 9.74734 48.5693 9.2675L48.5689 9.26922Z"
      fill="#FFC161"
    />
    <path
      d="M14.0649 44.7202C14.183 44.6417 14.2297 44.4861 14.1539 44.3606L13.4843 43.2508C13.4131 43.1327 13.242 43.0822 13.1246 43.1618C13.0066 43.2403 12.9599 43.3959 13.0357 43.5215L13.7053 44.6313C13.7765 44.7493 13.9475 44.7998 14.0649 44.7202Z"
      fill="#FFC161"
    />
    <path
      d="M12.3271 41.8403C12.4451 41.7617 12.4918 41.6062 12.4154 41.4795L11.7011 40.2957C11.6299 40.1776 11.4588 40.1271 11.3414 40.2067C11.2234 40.2853 11.1767 40.4408 11.2525 40.5664L11.9667 41.7502C12.038 41.8683 12.209 41.9188 12.3271 41.8403Z"
      fill="#FFC161"
    />
    <path
      d="M10.8062 43.9158L11.916 43.2462C12.0341 43.1749 12.0846 43.0039 12.0044 42.8854C11.9252 42.7663 11.7703 42.7207 11.6447 42.7964L10.5349 43.4661C10.4168 43.5373 10.3663 43.7083 10.4459 43.8257C10.5251 43.9448 10.6807 43.9915 10.8062 43.9158Z"
      fill="#FFC161"
    />
    <path
      d="M13.6868 42.1775L14.8707 41.4632C14.9888 41.392 15.0393 41.2209 14.959 41.1025C14.8798 40.9833 14.7249 40.9377 14.5994 41.0135L13.4155 41.7278C13.2974 41.799 13.2469 41.97 13.3265 42.0874C13.4057 42.2066 13.5613 42.2533 13.6868 42.1775Z"
      fill="#FFC161"
    />
    <path
      d="M24.6209 49.54C24.1531 48.3304 23.8407 47.0871 23.7234 45.7934C23.6169 44.6293 21.7924 44.6482 21.8982 45.8186C22.0308 47.2685 22.338 48.6731 22.8638 50.0322C23.2842 51.1196 25.0435 50.6334 24.6213 49.5383L24.6209 49.54Z"
      fill="#FFC161"
    />
    <path
      d="M23.026 44.1236C23.3745 43.9133 23.4866 43.4603 23.2763 43.1117C23.066 42.7632 22.613 42.6511 22.2644 42.8614C21.9159 43.0717 21.8038 43.5247 22.0141 43.8733C22.2244 44.2218 22.6774 44.3339 23.026 44.1236Z"
      fill="#FFC161"
    />
  </svg>
);

export const HowItWorksSection: React.FC<Props> = ({ videoUrl }) => {
  return (
    <section className="flex flex-col">
      {topWave}

      <div className="flex w-full items-center justify-center bg-how-it-works-background px-4 text-center" id="about">
        <div className="mt-20 flex w-full flex-col items-center">
          <div className="text-sectionTitle font-extrabold text-black">
            <FormattedMessage id={translations.pages.landing.howItWorksSection.title} />
          </div>

          <div className="mt-4 mb-16 max-w-lg text-how-it-works-content">
            <FormattedMessage id={translations.pages.landing.howItWorksSection.subtitle} />
          </div>

          <div className="relative aspect-16/9 h-full w-full max-w-xl md:max-w-4xl rounded-3xl shadow-2xl">
            <div className="absolute -right-10 -top-10 h-24 w-44">
              <Image src={dots} alt="Brown uneven dots" layout="fill" className="transition-all" />
            </div>

            <div className="absolute -left-12 bottom-0 h-24 w-44">
              <Image src={dots} alt="Brown uneven dots" layout="fill" className="transition-all" />
            </div>

            <div className="absolute z-20 h-full w-full overflow-hidden rounded-3xl">
              <video src={videoUrl} controls />
            </div>
          </div>

          <div className="my-12 max-w-lg md:max-w-2xl text-center text-how-it-works-content">
            <FormattedMessage
              id={translations.pages.landing.howItWorksSection.body}
              values={{
                br: <br />
              }}
            />
          </div>

          <Button
            onClick={() => window.open(REGISTRATION_URL, '_self')}
            appearance="gradient-orange"
            fat
            className=" px-12 text-2xl"
          >
            <FormattedMessage id={translations.inputs.buttons.tryFree} />
          </Button>

          <div className="mb-10 md:ml-10 w-full max-w-7xl">{chefBowl}</div>
        </div>
      </div>

      {bottomWave}
    </section>
  );
};
