import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

interface Props {
  question: string;
  answer: string;
}

export const QuestionItem: React.FC<Props> = ({ question, answer }) => {
  return (
    <React.Fragment>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between py-4 text-left text-header font-medium">
              <span>{question}</span>
              <div className="ml-6">
                <ChevronDownIcon
                  className={`${open ? 'rotate-180 transform text-orange-light' : 'text-black'} h-7 w-7 ml-auto`}
                />
              </div>
            </Disclosure.Button>

            <Disclosure.Panel className="pb-2 text-sm text-gray-500">{answer}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="h-[1px] w-full bg-border last:hidden" />
    </React.Fragment>
  );
};
