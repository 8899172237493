import Head from 'next/head';
import type { NextPage, GetStaticProps } from 'next';

import { getHomePage } from '@/services/api';
import { GetHomePageResponse } from '@chew/common/domains';

import { Header } from '@/components/Header';
import {
  HeroSection,
  HowItWorksSection,
  WhatWeOfferSection,
  MeetChefSection,
  ForParentsSection,
  PricingSection,
  FAQSection,
  CommunitySection,
  InstagramSection
} from '@/components/Sections';
import { Footer } from '@/components/Footer';

interface Props {
  page: GetHomePageResponse['page'];
}

const Home: NextPage<Props> = ({ page }) => {
  return (
    <main className="relative overflow-hidden text-black">
      <Head>
        <title>Chew</title>
        <link rel="icon" href="/favicon.svg" />
      </Head>

      <Header />

      <HeroSection title={page.welcome.title} subtitle={page.welcome.description} videoUrl={page.welcome.videoSrc} />

      <WhatWeOfferSection />

      <HowItWorksSection videoUrl={page.article1.videoSrc} />

      <MeetChefSection photoUrl={page.article2.imageSrc} />

      <ForParentsSection />

      <CommunitySection />

      <PricingSection promo={page.welcome.paymentText} />

      <FAQSection />

      <InstagramSection />

      <Footer />
    </main>
  );
};

export default Home;

export const getStaticProps: GetStaticProps<Props> = async () => {
  const { page } = await getHomePage();

  return { props: { page }, revalidate: 300 };
};
