import React from 'react';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid';

import { ReviewItem } from './ReviewItem';

interface Props {
  reviews: {
    text: string;
    author: string;
    photoUrl;
  }[];
}

export const ReviewItemsCarousel: React.FC<Props> = ({ reviews }) => {
  const [activeReview, setActiveReview] = React.useState(0);

  return (
    <div className="md:hidden flex flex-col items-center">
      <div className="grid grid-cols-2 items-center gap-10 my-8">
        <ArrowNarrowLeftIcon
          className={`${activeReview === 0 ? 'fill-gray-light' : 'fill-orange-light'} cursor-pointer h-7 w-7`}
          onClick={() => activeReview > 0 && setActiveReview(activeReview - 1)}
        />

        <ArrowNarrowRightIcon
          className={`${
            activeReview < reviews?.length - 1 ? 'fill-orange-light' : 'fill-gray-light'
          } cursor-pointer h-7 w-7`}
          onClick={() => activeReview < reviews?.length - 1 && setActiveReview(activeReview + 1)}
        />
      </div>

      <ReviewItem {...reviews[activeReview]} />

      <div className="flex items-center space-x-1 mt-6">
        {reviews.map((review, index) => (
          <div
            key={index}
            className={`h-3 rounded-full ${
              index === activeReview ? 'w-5 bg-orange-light' : 'w-3 bg-gray-light'
            } transition-all`}
          />
        ))}
      </div>
    </div>
  );
};
