import React from 'react';
import classnames from 'classnames';

import { CheckCircleIcon } from '@heroicons/react/solid';

interface Props {
  text: string;
  className?: string;
}

export const BenefitItem: React.FC<Props> = ({ text, className }) => {
  return (
    <div className={classnames('flex items-center gap-2', className)}>
      <CheckCircleIcon className="fill-green-800 h-6 w-6 " />

      <span className="text-sm text-black">{text}</span>
    </div>
  );
};
