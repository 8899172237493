import React from 'react';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

import { ReviewItem } from '@/components/ReviewItem';
import { Button } from '@chew/common/components/Button';
import { ReviewItemsCarousel } from '@/components/ReviewItemsCarousel';

import dagbladet from './assets/dagbladet.png';
import firstReview from './assets/emilie_avatar.jpeg';
import secondReview from './assets/silje_avatar.jpeg';

const reviews = [
  {
    text: translations.pages.landing.communitySection.reviews.firstReview.review,
    author: translations.pages.landing.communitySection.reviews.firstReview.author,
    photoUrl: firstReview
  },
  {
    text: translations.pages.landing.communitySection.reviews.secondReview.review,
    author: translations.pages.landing.communitySection.reviews.secondReview.author,
    photoUrl: secondReview
  }
];

export const CommunitySection: React.FC = () => {
  return (
    <section className="md:my-24 mx-auto flex flex-col w-full items-center px-4" id="testimonials">
      <div className="text-sectionTitle font-bold text-center max-w-lg">
        <FormattedMessage id={translations.pages.landing.communitySection.title} />
      </div>

      <ReviewItemsCarousel {...{ reviews }} />

      <div className="hidden md:flex items-center flex-wrap gap-4 justify-center mt-10">
        {reviews.map((item, index) => (
          <ReviewItem key={index} {...item} />
        ))}
      </div>

      <article className="flex flex-col mt-12">
        <div className="flex items-center gap-2">
          <span className="text-2xl font-extrabold">
            <FormattedMessage id={translations.pages.landing.communitySection.article.title} />
          </span>

          <div className="w-32 relative">
            <Image src={dagbladet} alt="Dagbladet logo" />
          </div>
        </div>
      </article>

      <div className="text-lg max-w-sm text-center mt-4">
        <FormattedMessage id={translations.pages.landing.communitySection.article.preview} />
      </div>

      <Button
        is="a"
        href="https://www.dagbladet.no/mat/nettkurs-for-barn---suksess/73843815"
        appearance="black"
        className="px-6 text-lg mt-6"
      >
        <FormattedMessage id={translations.inputs.buttons.readArticle} />
      </Button>
    </section>
  );
};
