import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';

interface Props {
  title: string;
  price: ReactNode;
  subtext: string;
  discount?: number;
}

export const PricingPlanItem: React.FC<Props> = ({ title, price, subtext, discount }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex gap-3 mb-1 items-center">
        <div className="font-extrabold text-header">{title}</div>

        {discount && (
          <div className="bg-orange-light rounded-full text-white text-xs m-auto px-2 py-[6px]">
            <FormattedMessage
              id={translations.pages.landing.pricingSection.plans.savings}
              values={{ value: discount }}
            />
          </div>
        )}
      </div>

      <div className="font-bold">{price}</div>

      <div className="text-gray text-sm">{subtext}</div>
    </div>
  );
};
