import React from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { LanguagesBasedFAQs } from '@/constants/FAQ';

import { QuestionItem } from '@/components/QuestionItem';

export const FAQSection: React.FC = () => {
  const router = useRouter();

  return (
    <section className="flex w-full flex-col max-w-2xl lg:max-w-4xl px-4 mb-20 mx-auto" id="faq">
      <div className="text-4xl font-extrabold text-center my-10 sm:my-16">
        <FormattedMessage id={translations.pages.landing.faqSection.title} />
      </div>

      {LanguagesBasedFAQs[router.locale].map((questionItem, index) => (
        <QuestionItem key={index} question={questionItem.question} answer={questionItem.answer} />
      ))}
    </section>
  );
};
