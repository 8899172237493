import React from 'react';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

import { translations } from '@/locales';
import { ellipsis } from '@/utils/text';
import { useMedia } from 'react-use';

interface Props {
  imageUrl;
  title: string;
  description: string;
  url: string;
}

export const ForParentsArticleItem: React.FC<Props> = ({ imageUrl, title, description, url }) => {
  const isMedium = useMedia('(min-width: 768px)');

  const readMore = () => {
    window.open(url, '_blank');
  };

  return (
    <div
      className="flex w-full bg-white shadow rounded-lg p-4 items-center max-w-xl mx-auto text-left cursor-pointer md:flex-col md:rounded-2xl md:items-baseline md:mx-0 md:max-w-[350px] md:cursor-default"
      {...(!isMedium && { onClick: readMore })}
    >
      <div className="relative h-11 w-11 bg-orange-200 rounded-lg aspect-square overflow-hidden md:rounded-2xl md:w-full md:h-auto">
        <Image src={imageUrl} alt="" layout="fill" objectFit="cover" />
      </div>

      <div className="ml-4 md:ml-0 text-body font-bold md:text-title md:mt-4">{title}</div>

      <div className="hidden md:block h-[72px] my-3 mt-auto">{ellipsis(description, 125)}</div>

      <a
        className="hidden md:block border-b-2 border-orange-light font-medium"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage id={translations.pages.landing.forParentsSection.readMore} />
      </a>
    </div>
  );
};
