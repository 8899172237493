import React from 'react';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

interface Props {
  text: string;
  photoUrl;
  author: string;
}

export const ReviewItem: React.FC<Props> = ({ text, photoUrl, author }) => {
  return (
    <div className="relative">
      <svg width="343" height="347" fill="none" xmlns="http://www.w3.org/2000/svg" className="drop-shadow-lg">
        <path
          d="M0.5 41.4303C0.5 33.6785 6.22673 27.1187 13.9076 26.0722L185.011 2.76054C210.47 -0.708092 236.36 0.773995 261.257 7.12535L330.831 24.8741C337.696 26.6254 342.5 32.8084 342.5 39.8931V309.887C342.5 317.712 336.668 324.31 328.902 325.27L170.791 344.817C151.072 347.255 131.099 346.733 111.534 343.27L13.2978 325.877C5.89499 324.567 0.5 318.133 0.5 310.615V41.4303Z"
          fill="white"
          stroke="#F1EEF2"
        />
      </svg>

      <div className="absolute inset-0 flex px-6 flex-col items-center justify-end mb-12">
        <div className="text-black font-bold italic">
          <FormattedMessage id={text} />
        </div>

        <div className="rounded-full h-16 w-16 bg-purple-300 mt-6 mb-2 overflow-hidden relative">
          <Image src={photoUrl} alt="User avatar" layout="fill" objectFit="cover" />
        </div>

        <div className="text-header font-extrabold text-center">
          <FormattedMessage id={author} />
        </div>
      </div>
    </div>
  );
};
